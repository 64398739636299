import { Button } from '@material-ui/core'
import React, { useEffect } from 'react'
import DownLoadUserProfileIcon from '../../asset/img/download-user-profile.svg'
import { Document, HeadingLevel, Packer, Paragraph, TextRun } from 'docx'
import { saveAs } from 'file-saver'
// import HTMLtoDOCX from 'html-to-docx'
import moment from 'moment'
import {
    EthnicityDropdownValues,
    ExerciseDropdownValues,
    FamilyPlansDropdownValues,
    LoveStyleDropdownValues,
    PoliticsDropdownValues,
    ReligionDropdownValues,
    dietaryDropdownValues,
} from '../../../src/Utils/advancedPreferenceDropDown'
// import { saveAs } from 'file-saver'
const DownloadUserProfile = ({
    data,
    matchMakeMeQuestions = [],
    zodiacDropdownOptions = [],
    sleepingHabbitsDropdownOptions = [],
    drinkingDropdownOptions = [],
    smokingDropdownOptions = [],
    relocateForLoveDropdownOptions = [],
    personalityDropdownValues = [],
    industryDropdownOptions = [],
    educationDropdownOptions = [],
    salaryDropdownOptions = [],
    lookingForDropdownOptions = [],
}) => {
    const buttonStyle = { padding: '10px 15px', borderRadius: 6 }

    const getUserBasicInfo = (userBasicInfoArr) => {
        let userBasicInfo = [
            {
                label: 'First Name',
                value: data?.firstName ? data?.firstName : '-',
            },
            {
                label: 'Last Name',
                value: data?.lastName ? data?.lastName : '-',
            },
            {
                label: 'Age',
                value: moment().diff(data?.dateOfBirth, 'years'),
            },
            {
                label: 'Email',
                value: data?.email ? data?.email : '-',
            },
            {
                label: 'Mobile Number',
                value: data?.mobileNumber ? data?.mobileNumber : '-',
            },
            {
                label: 'Date Of Birth',
                value: data?.dateOfBirth
                    ? moment(data?.dateOfBirth).format('DD.MM.YYYY')
                    : '-',
            },
            {
                label: 'Gender',
                value: data?.gender ? data?.gender : '-',
            },
            {
                label: 'Gender Description',
                value: data?.genderDescription ? data?.genderDescription : '-',
            },
            {
                label: 'Sexual Orientation',
                value: data?.sexualOrientation ? data?.sexualOrientation : '-',
            },
            {
                label: 'Country',
                value: data?.address?.country ? data?.address?.country : '-',
            },
            {
                label: 'State',
                value: data?.address?.state ? data?.address?.state : '-',
            },
            {
                label: 'City',
                value: data?.address?.city ? data?.address?.city : '-',
            },
            {
                label: 'Living',
                value: data?.living ? data?.living : '-',
            },
            {
                label: 'Location',
                value: data?.address?.location?.join(', '),
            },
            {
                label: 'Industry',
                value: data?.industry ? data?.industry : '-',
            },
            {
                label: 'Education',
                value: data?.education ? data?.education : '-',
            },

            {
                label: 'Profession/Occupation',
                value: Array.isArray(data?.occupation)
                    ? data?.occupation?.join(', ')
                    : data?.occupation,
            },
            {
                label: 'Salary',
                value: data?.salary ? data?.salary : '-',
            },
            {
                label: 'Instagram',
                value: data?.instagram
                    ? data?.instagram?.startsWith('http')
                        ? data?.instagram
                        : `https://www.instagram.com/${data?.instagram}`
                    : '-',
            },
            {
                label: 'LinkedIn',
                value: data?.linkedin ? data?.linkedin : '-',
            },
            {
                label: 'Height',
                value: data?.height?.type
                    ? data?.height?.type === 'CM'
                        ? data?.height?.height_cm +
                          ' (' +
                          data?.height?.type +
                          ')'
                        : data?.height?.height_feet +
                          ' (' +
                          data?.height?.type +
                          ')'
                    : '-',
            },

            // {
            //     label: 'Body Type',
            //     value: "-",
            // },
            {
                label: 'Marital Status',
                value: data?.marriage ? data?.marriage : '-',
            },
            // {
            //     label: 'Children',
            //     value: "-",
            // },
            // {
            //     label: 'Want Children',
            //     value: "-",
            // },
            {
                label: 'Ethnicity',
                value: data?.ethnicity ? data?.ethnicity : '-',
            },
            {
                label: 'Religion/practising',
                value: data?.religion ? data?.religion : '-',
            },
            {
                label: 'Politics',
                value: data?.politics ? data?.politics : '-',
            },

            {
                label: 'Values',
                value: Array.isArray(data?.values)
                    ? data?.values?.length
                        ? data?.values?.join(', ')
                        : '-'
                    : data?.values
                    ? data?.values
                    : '-',
            },
            {
                label: 'Vaccination Status',
                value: data?.vaccinationStatus ? data?.vaccinationStatus : '-',
            },
            {
                label: 'Smoking',
                value: data?.smoking ? data?.smoking : '-',
            },
            {
                label: 'Drinking',
                value: data?.drinking ? data?.drinking : '-',
            },
            {
                label: 'Exercise',
                value: data?.exercise ? data?.exercise : '-',
            },
            {
                label: 'Diet',
                value: data?.diet ? data?.diet : '-',
            },
            {
                label: 'Pet',
                value: data?.pet ? data?.pet : '-',
            },
            {
                label: 'Likes',
                value: Array.isArray(data?.likes)
                    ? data?.likes?.length
                        ? data?.likes?.join(', ')
                        : '-'
                    : data?.likes
                    ? data?.likes
                    : '-',
            },
            {
                label: 'Dislikes',
                value: Array.isArray(data?.dislikes)
                    ? data?.dislikes?.length
                        ? data?.dislikes?.join(', ')
                        : '-'
                    : data?.dislikes
                    ? data?.dislikes
                    : '-',
            },
            {
                label: 'Attachment Style',
                value: data?.attachmentStyle ? data?.attachmentStyle : '-',
            },
            {
                label: 'Family Plans',
                value: Array.isArray(data?.children)
                    ? data?.children?.length
                        ? data?.children?.join(', ')
                        : '-'
                    : data?.children
                    ? data?.children
                    : '-',
            },
            {
                label: 'Subscription',
                value: data?.subscription ? data?.subscription : '-',
            },
            {
                label: 'Expire Package:',
                value: data?.expirePackage
                    ? moment(data?.expirePackage).format('DD.MM.YYYY')
                    : '-',
            },
            {
                label: 'Why To Join',
                value: data?.whyToJoin ? data?.whyToJoin : '-',
            },
            {
                label: 'Dating Approach',
                value: data?.dateingApproach ? data?.dateingApproach : '-',
            },
            {
                label: 'Spend On Free Time',
                value: data?.spendOnFreeTime ? data?.spendOnFreeTime : '-',
            },
            {
                label: 'Looking For',
                value: Array.isArray(data?.lookingFor)
                    ? data?.lookingFor?.length
                        ? data?.lookingFor?.join(', ')
                        : '-'
                    : data?.lookingFor
                    ? data?.lookingFor
                    : '-',
            },
            {
                label: 'Invitation Code',
                value: data?.invitationCode ? data?.invitationCode : '-',
            },
            {
                label: 'Block Reason',
                value: data?.blockReason ? data?.blockReason : '-',
            },
            {
                label: 'Match Make Me',
                value: data?.matchMakeMe ? 'Yes' : 'No',
            },
            {
                label: 'Mobile Verified',
                value: data?.isMobileVerified ? 'Yes' : 'No',
            },
            {
                label: 'Email Verified',
                value: data?.isEmailVerified ? 'Yes' : 'No',
            },
            {
                label: 'LinkedIn Verified',
                value: data?.isLinkedinVerified ? 'Yes' : 'No',
            },
            {
                label: 'Facebook Verified',
                value: data?.isFacebookVerified ? 'Yes' : 'No',
            },
            {
                label: 'Instagram Verified',
                value: data?.isInstagramVerified ? 'Yes' : 'No',
            },
        ]
        userBasicInfo.forEach((objItem) => {
            userBasicInfoArr.push(
                new Paragraph({
                    children: [
                        new TextRun({
                            text: objItem.label + ': ' + objItem.value,
                            bold: false,
                            size: 28,
                            font: 'calibri',
                        }),
                    ],
                })
            )
        })
    }
    const getUserMatchMakingQuestionnair = (userBasicInfoArr) => {
        matchMakeMeQuestions.forEach((objItem) => {
            console.log('objItem', objItem)
            objItem?.questionnair.forEach((questionnair) => {
                const question = questionnair.question_title
                    ? 'Q. ' + questionnair.question_title
                    : ''
                let answer =
                    questionnair.question_type === 'OPTIONAL'
                        ? questionnair.response_arr?.length
                            ? questionnair.response_arr.join(', ')
                            : null
                        : questionnair?.response_text
                answer = answer ? 'Ans: ' + answer : 'Ans: '
                userBasicInfoArr.push(
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: question,
                                bold: false,
                                size: 28,
                                font: 'calibri',
                                break: 1,
                            }),
                        ],
                    })
                )
                userBasicInfoArr.push(
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: answer,
                                bold: false,
                                size: 26,
                                font: 'calibri',
                            }),
                        ],
                    })
                )
            })
        })
    }

    const userMatchPreferenceInfo = (userMatchPreferenceArr) => {
        const matchPreferenceInfo = [
            {
                label: 'Preferred Looking For',
                value: data?.preferences?.lookingFor?.length
                    ? data?.preferences?.lookingFor?.join(', ')
                    : '-',
            },
            {
                label: 'Preferred Gender',
                value: data?.preferences?.gender
                    ? data?.preferences?.gender
                    : '-',
            },
            {
                label: 'Preferred Age Range',
                value: data?.preferences?.age ? data?.preferences?.age : '-',
            },

            {
                label: 'Show people slightly out of my preferred range if I run out of profiles to see (Age)',
                value: data?.preferences?.isShowOtherPeopleIfRunOutAge
                    ? 'Yes'
                    : data?.preferences?.isShowOtherPeopleIfRunOutAge === false
                    ? 'No'
                    : '-',
            },
            {
                label: 'Preferred Ethnicities',
                value: data?.preferences?.ethnicity?.length
                    ? data?.preferences?.ethnicity[0] === ''
                        ? '-'
                        : data?.preferences?.ethnicity.join(', ')
                    : '-',
            },
            {
                label: 'Preferred Height',
                value: data?.preferences?.height
                    ? data?.preferences?.height
                    : '-',
            },
            {
                label: 'Preferred Religious',
                value: data?.preferences?.religion?.length
                    ? data?.preferences?.religion[0] === ''
                        ? '-'
                        : data?.preferences?.religion?.join(', ')
                    : '-',
            },
            {
                label: 'Preferred Sexual Orientation',
                value: data?.preferences?.sexualOrientation
                    ? data?.preferences?.sexualOrientation
                    : '-',
            },
            {
                label: 'Preferred Distance',
                value: data?.preferences?.distance
                    ? data?.preferences?.distance
                    : '-',
            },

            {
                label: 'Show people further away if I run out of profiles to see (Distance)',
                value: data?.preferences?.isShowOtherPeopleIfRunOutDistance
                    ? 'Yes'
                    : data?.preferences?.isShowOtherPeopleIfRunOutDistance ===
                      false
                    ? 'No'
                    : '-',
            },
            // {
            //     label: 'Preferred body type',
            //     value: data?.lookingFor?.join(', '),
            // },
            {
                label: 'Preferred Country',
                value: data?.preferences?.address?.country
                    ? data?.preferences?.address?.country
                    : '-',
            },
            {
                label: 'Preferred State',
                value: data?.preferences?.address?.state
                    ? data?.preferences?.address?.state
                    : '-',
            },
            {
                label: 'Preferred City',
                value: data?.preferences?.address?.city
                    ? data?.preferences?.address?.city
                    : '-',
            },
            {
                label: 'Preferred Location',
                value: data?.preferences?.address?.location
                    ? data?.preferences?.address?.location?.join(', ')
                    : '-',
            },
        ]
        matchPreferenceInfo.forEach((objItem) => {
            userMatchPreferenceArr.push(
                new Paragraph({
                    children: [
                        new TextRun({
                            text: objItem.label + ': ' + objItem.value,
                            bold: false,
                            size: 28,
                            font: 'calibri',
                        }),
                    ],
                })
            )
        })
    }

    const userAdvancedMatchPreferenceInfo = (userMatchPreferenceArr) => {
        const advancedMatchPreferenceInfo = [
            {
                label: 'Looking For',
                value: data?.advancedFilterPreferences?.lookingFor
                    ? data?.advancedFilterPreferences?.lookingFor?.length === 0
                        ? lookingForDropdownOptions.join(', ')
                        : data?.advancedFilterPreferences?.lookingFor?.join(
                              ', '
                          )
                    : '-',
            },
            {
                label: 'Ethnicity',
                value: data?.advancedFilterPreferences?.ethnicity
                    ? data?.advancedFilterPreferences?.ethnicity?.length == 0
                        ? EthnicityDropdownValues?.join(', ')
                        : data?.advancedFilterPreferences?.ethnicity?.join(', ')
                    : '-',
            },
            {
                label: 'Religion',
                value: data?.advancedFilterPreferences?.religion
                    ? data?.advancedFilterPreferences?.religion?.length === 0
                        ? ReligionDropdownValues?.join(', ')
                        : data?.advancedFilterPreferences?.religion?.join(', ')
                    : '-',
            },
            {
                label: 'Family Plans',
                value: data?.advancedFilterPreferences?.children
                    ? data?.advancedFilterPreferences?.children?.length === 0
                        ? FamilyPlansDropdownValues.join(', ')
                        : data?.advancedFilterPreferences?.children?.join(', ')
                    : '-',
            },
            {
                label: 'Salary',
                value: data?.advancedFilterPreferences?.salary
                    ? data?.advancedFilterPreferences?.salary?.length === 0
                        ? salaryDropdownOptions?.join(', ')
                        : data?.advancedFilterPreferences?.salary?.join(', ')
                    : '-',
            },
            {
                label: 'Height',
                value: data?.advancedFilterPreferences?.height?.type
                    ? data?.advancedFilterPreferences?.height?.from +
                      ' - ' +
                      data?.advancedFilterPreferences?.height?.to +
                      ' (' +
                      data?.advancedFilterPreferences?.height?.type +
                      ')'
                    : '-',
            },
            {
                label: 'Education',
                value: data?.advancedFilterPreferences?.education
                    ? data?.advancedFilterPreferences?.education?.length === 0
                        ? educationDropdownOptions?.join(', ')
                        : data?.advancedFilterPreferences?.education?.join(', ')
                    : '-',
            },
            {
                label: 'Industry',
                value: data?.advancedFilterPreferences?.industry
                    ? data?.advancedFilterPreferences?.industry?.length === 0
                        ? industryDropdownOptions.join(', ')
                        : data?.advancedFilterPreferences?.industry?.join(', ')
                    : '-',
            },
            {
                label: 'Politics',
                value: data?.advancedFilterPreferences?.politics
                    ? data?.advancedFilterPreferences?.politics?.length === 0
                        ? PoliticsDropdownValues?.join(', ')
                        : data?.advancedFilterPreferences?.politics?.join(', ')
                    : '-',
            },
            {
                label: 'Smoking',
                value: data?.advancedFilterPreferences?.smoking
                    ? data?.advancedFilterPreferences?.smoking?.length === 0
                        ? smokingDropdownOptions?.join(', ')
                        : data?.advancedFilterPreferences?.smoking?.join(', ')
                    : '-',
            },
            {
                label: 'Drinking',
                value: data?.advancedFilterPreferences?.drinking
                    ? data?.advancedFilterPreferences?.drinking?.length === 0
                        ? drinkingDropdownOptions.join(', ')
                        : data?.advancedFilterPreferences?.drinking?.join(', ')
                    : '-',
            },
            {
                label: 'Zodiac',
                value: data?.advancedFilterPreferences?.zodiac
                    ? data?.advancedFilterPreferences?.zodiac?.length === 0
                        ? zodiacDropdownOptions?.join(', ')
                        : data?.advancedFilterPreferences?.zodiac?.join(', ')
                    : '-',
            },
            {
                label: 'Love Style',
                value: data?.advancedFilterPreferences?.loveStyle
                    ? data?.advancedFilterPreferences?.loveStyle?.length === 0
                        ? LoveStyleDropdownValues?.join(', ')
                        : data?.advancedFilterPreferences?.loveStyle?.join(', ')
                    : '-',
            },
            {
                label: 'Personality Type',
                value: data?.advancedFilterPreferences?.personalityType
                    ? data?.advancedFilterPreferences?.personalityType
                          ?.length === 0
                        ? personalityDropdownValues.join(', ')
                        : data?.advancedFilterPreferences?.personalityType?.join(
                              ', '
                          )
                    : '-',
            },
            {
                label: 'Exercise',
                value: data?.advancedFilterPreferences?.exercise
                    ? data?.advancedFilterPreferences?.exercise?.length === 0
                        ? ExerciseDropdownValues.join(', ')
                        : data?.advancedFilterPreferences?.exercise?.join(', ')
                    : '-',
            },

            {
                label: 'Sleeping Habits',
                value: data?.advancedFilterPreferences?.sleepingHabits
                    ? data?.advancedFilterPreferences?.sleepingHabits
                          ?.length === 0
                        ? sleepingHabbitsDropdownOptions.join(', ')
                        : data?.advancedFilterPreferences?.sleepingHabits?.join(
                              ', '
                          )
                    : '-',
            },
            {
                label: 'Dietary Preference',
                value: data?.advancedFilterPreferences?.diet
                    ? data?.advancedFilterPreferences?.diet?.length === 0
                        ? dietaryDropdownValues.join(', ')
                        : data?.advancedFilterPreferences?.diet?.join(', ')
                    : '-',
            },
            {
                label: 'Relocate For Love',
                value: data?.advancedFilterPreferences?.relocateForLove
                    ? data?.advancedFilterPreferences?.relocateForLove
                          ?.length === 0
                        ? relocateForLoveDropdownOptions.join(', ')
                        : data?.advancedFilterPreferences?.relocateForLove?.join(
                              ', '
                          )
                    : '-',
            },
            {
                label: 'Show me other people if I run out',
                value: data?.isShowOtherPeopleIfRunOut
                    ? 'Yes'
                    : data?.isShowOtherPeopleIfRunOut === false
                    ? 'No'
                    : '-',
            },
        ]
        advancedMatchPreferenceInfo.forEach((objItem) => {
            userMatchPreferenceArr.push(
                new Paragraph({
                    children: [
                        new TextRun({
                            text: objItem.label + ': ' + objItem.value,
                            bold: false,
                            size: 28,
                            font: 'calibri',
                            break: 1,
                        }),
                    ],
                })
            )
        })
    }

    const handleDownloadUserProfile = () => {
        const paragraphArr = [
            new Paragraph({
                children: [
                    // heading: HeadingLevel.HEADING_1,
                    new TextRun({
                        text: data?.firstName + ' Profile Details',
                        bold: true,
                        size: 40,
                        font: 'calibri',
                        break: 1,
                    }),
                ],
            }),
        ]
        paragraphArr.push(
            new Paragraph({
                // heading: HeadingLevel.HEADING_1,
                children: [
                    new TextRun({
                        text: 'Basic Details:',
                        bold: true,
                        size: 35,
                        font: 'calibri',
                        break: 1,
                    }),
                ],
            })
        )
        // get user basic details
        getUserBasicInfo(paragraphArr)

        paragraphArr.push(
            new Paragraph({
                heading: HeadingLevel.HEADING_1,
                children: [
                    new TextRun({
                        text: 'Match Preferences:',
                        bold: true,
                        size: 35,
                        font: 'calibri',
                        break: 1,
                    }),
                ],
            })
        )

        // add user match preferences info
        userMatchPreferenceInfo(paragraphArr)

        paragraphArr.push(
            new Paragraph({
                heading: HeadingLevel.HEADING_1,
                children: [
                    new TextRun({
                        text: 'Advanced Match Preferences:',
                        bold: true,
                        size: 35,
                        font: 'calibri',
                        break: 1,
                    }),
                ],
            })
        )

        // add user match preferences info
        userAdvancedMatchPreferenceInfo(paragraphArr)

        paragraphArr.push(
            new Paragraph({
                // heading: HeadingLevel.HEADING_1,
                children: [
                    new TextRun({
                        text: 'Match Make Me Questionnair:',
                        bold: true,
                        size: 35,
                        font: 'calibri',
                        break: 1,
                    }),
                ],
            })
        )
        // match making questionnair
        getUserMatchMakingQuestionnair(paragraphArr)

        const doc = new Document({
            styles: {
                paragraphStyles: [
                    {
                        id: 'Heading1',
                        name: 'Heading 1',
                        basedOn: 'Normal',
                        next: 'Normal',
                        quickFormat: true,
                        run: {
                            size: 38,
                            font: 'calibri',
                            bold: true,
                            color: '#000000',
                        },
                    },
                ],
            },
            sections: [
                {
                    children: paragraphArr,
                },
            ],
        })
        const fileName = data?.firstName + '_' + data?._id + '' + '.docx'
        Packer.toBlob(doc).then((blob) => {
            saveAs(blob, fileName)
        })
    }

    // const userIntroInfo = () => {
    //     const userNameAndAge =
    //         (data?.lastName
    //             ? `${data?.firstName} ${data?.lastName}, `
    //             : `${data?.firstName}, `) +
    //         `${moment().diff(data?.dateOfBirth, 'years')}`
    //     const userImageUrl = data?.images.length ? data?.images[0]?.croped : ''
    //     console.log('userImageUrl_userImageUrl', userImageUrl)
    //     const userIntroStr = `<div style="display:flex">
    // <div>
    // <img src = ${userImageUrl} width= "130px" height="150px" />
    // </div>
    // <div>
    // ${userNameAndAge}
    // </div>
    // </div>
    // <br/>`
    //     return userIntroStr
    // }
    // const userIntroInfo = () => {
    //     const userNameAndAge =
    //         (data?.lastName
    //             ? `${data?.firstName} ${data?.lastName}, `
    //             : `${data?.firstName}, `) +
    //         `${moment().diff(data?.dateOfBirth, 'years')}`
    //     const userImageUrl = data?.images.length ? data?.images[0]?.croped : ''
    //     //     <div>
    //     // <img src = ${userImageUrl} width= "130px" height="150px" />
    //     // </div>
    //     const userIntroStr = `
    //  ${userNameAndAge}`
    //     return userIntroStr
    // }

    // const handleDownloadUserProfile = (element) => {
    //     let preHtml = `<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body><h1>${data?.firstName} Profile Details</h1><h3>Basic Details: </h3>`

    //     // preHtml += userIntroInfo()
    //     preHtml += getUserBasicInfo()
    //     // preHtml += '<br/>'
    //     // add user about info
    //     preHtml += userMatchPreferenceInfo()
    //     // preHtml += '<br/>'
    //     let postHtml = '</body></html>'
    //     let html =
    //         preHtml + document.getElementById(element).innerHTML + postHtml

    //     let blob = new Blob(['\ufeff', html], {
    //         type: 'application/msword',
    //     })

    //     console.log('final_html', html)
    //     // Specify link url
    //     let url =
    //         'data:application/vnd.ms-word;charset=utf-8,' +
    //         encodeURIComponent(html)

    //     // Specify file name
    //     let filename = data?.firstName + '_' + data?._id + '' + '.docx'

    //     // Create download link element
    //     let downloadLink = document.createElement('a')

    //     document.body.appendChild(downloadLink)

    //     if (navigator?.msSaveOrOpenBlob) {
    //         console.log('data_debug', blob)
    //         navigator?.msSaveOrOpenBlob(blob, filename)
    //     } else {
    //         // Create a link to the file
    //         downloadLink.href = url

    //         // Setting the file name
    //         downloadLink.download = filename

    //         //triggering the function
    //         downloadLink.click()
    //     }

    //     document.body.removeChild(downloadLink)
    // }
    return (
        <Button
            onClick={() => handleDownloadUserProfile()}
            // disabled={status}
            variant="outlined"
            style={buttonStyle}
        >
            <div id="exportContent"></div>
            <img
                src={DownLoadUserProfileIcon}
                alt="delete user"
                width={15}
                height={15}
                style={{ marginRight: 3 }}
            />
        </Button>
    )
}

export default DownloadUserProfile

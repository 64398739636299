import { FormLabel, Grid, Typography } from '@material-ui/core'
import {
    Checkbox,
    CircularProgress,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Stack,
    TextField,
} from '@mui/material'
import React, { useEffect, useState, useRef, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { ContentState, EditorState } from 'draft-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { styles } from '../../styles/styles'
import {
    add_hub,
    get_all_dropdowns,
    get_hub_details_by_id,
} from '../../../src/api/UserRequest_Api'
import UserImageUploadSection from './UserImageUpload'
import { Button as ButtonCore } from '@material-ui/core'
import imageCompression from 'browser-image-compression'
import Store from '../../redux/Store'
import { set_message } from '../../redux/ActionCreators'
import { useParams } from 'react-router-dom/cjs/react-router-dom'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import JoditEditor from 'jodit-react'
const BlogModule = () => {
    const editorJodit = useRef(null)
    const [contentJodit, setContentJodit] = useState('')
    console.log('contentJodit', contentJodit)
    // const configJodit = useMemo(
    //     {
    //         readonly: false, // all options from https://xdsoft.net/jodit/docs/,
    //         placeholder: 'Description',
    //         speechRecognize: false,
    //     },
    //     []
    // )
    //  const editorConfig = {
    //     readonly: false,
    //     toolbar: true,
    //     spellcheck: true,
    //     language: 'en',
    //     toolbarButtonSize: 'medium',
    //     // toolbarAdaptive: false,
    //     showCharsCounter: true,
    //     showWordsCounter: true,
    //     showXPathInStatusbar: false,
    //     askBeforePasteHTML: true,
    //     askBeforePasteFromWord: true,
    //     //defaultActionOnPaste: "insert_clear_html",
    //     // buttons: buttons,
    //     uploader: {
    //         insertImageAsBase64URI: true
    //     },
    //     width: 800,
    //     minHeight: 400,
    //     controls: {
    //         font: {
    //             command: 'fontname',
    //             list: {
    //                 "'Open Sans',sans-serif": 'Open Sans',
    //                 'Helvetica,sans-serif': 'Helvetica',
    //                 'Arial,Helvetica,sans-serif': 'Arial',
    //                 'Georgia,serif': 'Georgia',
    //                 'Impact,Charcoal,sans-serif': 'Impact',
    //                 'Tahoma,Geneva,sans-serif': 'Tahoma',
    //                 "'Times New Roman',Times,serif": 'Times New Roman',
    //                 'Verdana,Geneva,sans-serif': 'Verdana',
    //                 'Consolas,monaco,monospace': 'Consolas'
    //             }
    //         }
    //     }
    // };
    const history = useHistory()
    const style = styles()
    const params = useParams()
    console.log('params', params)
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [contentState, setContentState] = useState('')
    const [loading, setLoading] = useState(false)
    const [buttonLoading, setButtonLoading] = useState(false)
    const [blogData, setBlogData] = useState(null)
    const [blobImageLocalURL, setBlobImageLocalURL] = useState(null)
    const [userImages, setUserImages] = useState(null)
    const [hubStatusDropdown, setHubStatusDropdown] = useState([])
    const [categoryDropdown, setCategoryDropdown] = useState([])
    const handleSetUserImages = (imagesArr) => {
        setUserImages(imagesArr)
    }

    const handleContentStateChange = (contentState) => {
        const htmlWithContent = draftToHtml(contentState)
        setContentState(htmlWithContent)
    }

    const handleEditorStateChange = (editorState) => {
        setEditorState(editorState)
    }
    const form = useForm({ shouldUnregister: true })

    const {
        register,
        setValue,
        control,
        handleSubmit,
        reset,
        getValues,
        setError,
        setFocus,
        values,
        formState: { errors },
    } = form
    console.log('form values', getValues())
    const validationAddUser = (data) => {
        // console.log(
        //     'userImages11',
        //     null === null,
        //     userImages,
        //     blobImageLocalURL
        // )
        let message = ''
        if (
            data.status.toUpperCase() === 'DRAFT' ||
            data.status.toUpperCase() === 'UNPUBLISHED'
        ) {
            if (!data.title) {
                message = 'Title is required'
            }
        } else if (data.status.toUpperCase() === 'PUBLISHED') {
            if (!data.title) {
                message = 'Title is required'
            } else if (!data?.category) {
                message = 'Category is required'
            }
            // else if (!data?.short_description) {
            //     message = 'Short description is required'
            // }

            // else if (!data?.button_title) {
            //     message = 'Button title is required'
            // }  else if (!data?.button_link) {
            //     message = 'Button link is required'
            // }
            else if (!contentJodit) {
                message = 'Description is required'
            } else if (data?.button_link) {
                if (
                    data?.button_link?.toLowerCase()?.startsWith('http://') ||
                    data?.button_link?.toLowerCase()?.startsWith('https://') ||
                    data?.button_link?.toLowerCase()?.startsWith('reddi')
                ) {
                } else {
                    message = 'Button link is not valid'
                }
            }
            // if (
            //     contentState &&
            //     contentState.trim().length === 7 &&
            //     contentState.trim() == '<p></p>' &&
            //     message === ''
            // ) {
            //     message = 'Description is required'
            // }
            console.log('contentJodit111', contentJodit)
            if (
                contentJodit &&
                contentJodit.trim().length === 11 &&
                contentJodit.trim() == '<p><br></p>' &&
                message === ''
            ) {
                message = 'Description is required'
            }
            if (blobImageLocalURL === null && message === '') {
                message = 'Image is required'
            }
        }

        if (message) {
            Store.dispatch(
                set_message({
                    mode: true,
                    message: message,
                    color: 'error',
                })
            )
            return true
        }
    }
    const onSubmit = async (data) => {
        if (validationAddUser(data)) return
        let compressedFile = null
        setButtonLoading(true)
        if (userImages) {
            console.log(`originalFile size ${userImages.size / 1024 / 1024} MB`)
            const options = {
                maxSizeMB: 1,
                // maxWidthOrHeight: 250,
                useWebWorker: true,
            }
            compressedFile = userImages
            if (compressedFile?.size > 3145728) {
                compressedFile = await imageCompression(userImages, options)
            }
            console.log(
                `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
            )
            console.log('userImages_userImages', editorState)
        } else {
            if (data?.image && blobImageLocalURL) {
                compressedFile = data?.image ?? null
            } else {
                compressedFile = null
            }
        }

        const formData = new FormData()
        formData.append('image', compressedFile)

        formData.append('title', data?.title ? data?.title : null)
        formData.append('category', data?.category ? data?.category : null)
        // formData.append(
        //     'short_description',
        //     data?.short_description ? data?.short_description : null
        // )
        // formData.append('description', contentState ? contentState : null)
        formData.append('description', contentJodit ? contentJodit : null)
        formData.append('status', data?.status?.toUpperCase())
        formData.append('button_link', data?.button_link ?? null)
        formData.append('button_title', data?.button_title ?? null)
        if (params?.id !== 'add') {
            formData.append('id', params?.id)
        }
        await add_hub(formData)
        setButtonLoading(false)
        history.push('/hub')
    }
    useEffect(() => {
        get_all_dropdowns()
            .then((res) => {
                const blogCategoryDropdown = res?.find((d) => {
                    if (d.dropdownTitle === 'blogs_category') {
                        return true
                    }
                })

                setCategoryDropdown(blogCategoryDropdown?.optionList)

                const hubStatusDropdown = res?.find((d) => {
                    if (d.dropdownTitle === 'hub_status') {
                        return true
                    }
                })
                setHubStatusDropdown(hubStatusDropdown?.optionList)
            })
            .catch((err) => {})
        if (params?.id?.toLowerCase() !== 'add') {
            setLoading(true)
            get_hub_details_by_id(params?.id)
                .then((res) => {
                    if (res?.data) {
                        setBlogData(res?.data)
                        for (const key in res?.data) {
                            if (Object.hasOwnProperty.call(res?.data, key)) {
                                if (key?.toUpperCase() === 'STATUS') {
                                    if (
                                        res?.data[key]?.toUpperCase() ===
                                        'DRAFT'
                                    ) {
                                        setValue(key, 'Draft')
                                    } else if (
                                        res?.data[key]?.toUpperCase() ===
                                        'PUBLISHED'
                                    ) {
                                        setValue(key, 'Published')
                                    } else if (
                                        res?.data[key]?.toUpperCase() ===
                                        'UNPUBLISHED'
                                    ) {
                                        setValue(key, 'Unpublished')
                                    }
                                } else {
                                    setValue(key, res?.data[key])
                                }
                            }
                        }
                        setBlobImageLocalURL(res?.data?.image)
                        setContentJodit(res?.data?.description)
                        // const contentBlock = htmlToDraft(res?.data?.description)
                        // if (contentBlock) {
                        //     const contentState1 =
                        //         ContentState.createFromBlockArray(
                        //             contentBlock.contentBlocks
                        //         )
                        //     const newEditorState =
                        //         EditorState.createWithContent(contentState1)
                        //     setEditorState(newEditorState)
                        //     setContentState(res?.data?.description)
                        // }
                    }
                })
                .catch((err) => {
                    setLoading(false)
                })
        } else {
            const objBlogData = {
                title: '',
                category: null,
                // short_description: '',
                status: 'Draft',
                button_link: null,
                button_title: null,
            }

            for (const key in objBlogData) {
                if (Object.hasOwnProperty.call(objBlogData, key)) {
                    setValue(key, objBlogData[key])
                }
            }
        }
        setLoading(false)
    }, [])

    return !loading ? (
        <Grid className="customJoditPadding" container>
            <Grid container spacing={2} className={style.box}>
                <Grid container item xs={4}>
                    <Grid item xs={12}>
                        <Stack direction="column">
                            <FormLabel htmlFor="title">
                                Title
                                <span
                                    style={{
                                        color: '#dc3545',
                                    }}
                                >
                                    *
                                </span>
                            </FormLabel>

                            <TextField
                                {...register('title')}
                                id="title"
                                placeholder="Title"
                                fullWidth
                                margin="normal"
                                size="small"
                            />
                        </Stack>
                    </Grid>
                </Grid>
                <Grid item container xs={4}>
                    <Grid item xs={12}>
                        <Stack direction="column">
                            <FormLabel htmlFor="category" sx={{ pt: 4 }}>
                                Category
                                {/* <span
                                    style={{
                                        color: '#dc3545',
                                    }}
                                >
                                    *
                                </span> */}
                            </FormLabel>
                            <FormControlLabel
                                style={{
                                    paddingTop: '16px',
                                    paddingLeft: '12px',
                                    marginRight: '0',
                                }}
                                control={
                                    <Controller
                                        name="category"
                                        {...register('category')}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                labelId="category"
                                                id="demo-simple-select-category"
                                                fullWidth
                                                margin="normal"
                                                size="small"
                                                value={
                                                    field.value
                                                        ? field.value
                                                        : ''
                                                }
                                                onChange={(event) =>
                                                    field.onChange(
                                                        event.target.value
                                                    )
                                                }
                                            >
                                                <MenuItem value={'0'}>
                                                    Select Category
                                                </MenuItem>
                                                {categoryDropdown?.map(
                                                    (option) => {
                                                        return (
                                                            <MenuItem
                                                                key={
                                                                    option?._id
                                                                }
                                                                value={
                                                                    option?.name
                                                                }
                                                            >
                                                                {option?.name}
                                                            </MenuItem>
                                                        )
                                                    }
                                                )}
                                            </Select>
                                        )}
                                    />
                                }
                            />
                        </Stack>
                    </Grid>
                </Grid>
                <Grid item container xs={4}>
                    <Grid item xs={12}>
                        <Stack direction="column">
                            <FormLabel htmlFor="category" sx={{ pt: 4 }}>
                                Hub Status
                            </FormLabel>
                            <FormControlLabel
                                style={{
                                    paddingTop: '16px',
                                    paddingLeft: '12px',
                                    marginRight: '0',
                                }}
                                control={
                                    <Controller
                                        name="status"
                                        {...register('status')}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                labelId="status"
                                                id="demo-simple-select-status"
                                                fullWidth
                                                margin="normal"
                                                size="small"
                                                value={
                                                    field.value
                                                        ? field.value
                                                        : ''
                                                }
                                                onChange={(event) =>
                                                    field.onChange(
                                                        event.target.value
                                                    )
                                                }
                                            >
                                                <MenuItem value={'0'}>
                                                    Select Hub Status
                                                </MenuItem>
                                                {hubStatusDropdown?.map(
                                                    (option) => {
                                                        return (
                                                            <MenuItem
                                                                key={
                                                                    option?.name
                                                                }
                                                                value={
                                                                    option?.name
                                                                }
                                                            >
                                                                {option?.name}
                                                            </MenuItem>
                                                        )
                                                    }
                                                )}
                                            </Select>
                                        )}
                                    />
                                }
                            />
                        </Stack>
                    </Grid>
                </Grid>
                {/* <Grid container item xs={12}>
                    <Grid item xs={12}>
                        <Stack direction="column">
                            <FormLabel htmlFor="short_description">
                                Short Description
                            </FormLabel>

                            <TextField
                                {...register('short_description')}
                                id="short_description"
                                placeholder="Short Description"
                                fullWidth
                                margin="normal"
                                size="small"
                            />
                        </Stack>
                    </Grid>
                </Grid> */}

                <Grid container item xs={12} style={{ minHeight: '330px' }}>
                    <Grid
                        item
                        xs={12}
                        style={{
                            marginTop: '10',
                            border: `2px solid rgba(5, 6, 15, 0.7)`,
                            borderRadius: '4px',
                        }}
                    >
                        <JoditEditor
                            ref={editorJodit}
                            value={contentJodit}
                            config={{
                                readonly: false, // all options from https://xdsoft.net/jodit/docs/,
                                placeholder: 'Description',
                                speechRecognize: false,
                                showStatusbar: false,
                                showCharsCounter: false,
                                showWordsCounter: false,
                                showXPathInStatusbar: false,
                                disablePlugins: 'add-new-line',
                                // allowResizeY: false,
                                // nl2brInPlainTextnl2brInPlainText: false,
                                // height: 300,

                                // minHeight: 400,

                                // style: {
                                //     paddingTop: '0',
                                // },
                            }}
                            tabIndex={1} // tabIndex of textarea
                            onBlur={(newContent) => setContentJodit(newContent)} // preferred to use only this option to update the content for performance reasons
                            onChange={(newContent) => {}}
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={4}>
                    <Grid item xs={12}>
                        <Stack direction="column">
                            <FormLabel htmlFor="title">
                                Button Title(Optional){' '}
                            </FormLabel>
                            <TextField
                                {...register('button_title')}
                                id="button_title"
                                placeholder="Button Title"
                                fullWidth
                                margin="normal"
                                size="small"
                            />
                        </Stack>
                    </Grid>
                </Grid>
                <Grid container item xs={4}>
                    <Grid item xs={12}>
                        <Stack direction="column">
                            <FormLabel htmlFor="button_link">
                                Button Link(Optional)
                            </FormLabel>
                            <TextField
                                {...register('button_link')}
                                id="button_link"
                                placeholder="Button Link"
                                fullWidth
                                margin="normal"
                                size="small"
                            />
                        </Stack>
                    </Grid>
                </Grid>
                <Grid container item xs={4}></Grid>
                <Grid container item xs={12} style={{ marginTop: '16px' }}>
                    <Grid item xs={12}>
                        <UserImageUploadSection
                            images={userImages}
                            setUserImages={handleSetUserImages}
                            blobImageLocalURL={blobImageLocalURL}
                            setBlobImageLocalURL={setBlobImageLocalURL}
                        />
                    </Grid>
                </Grid>

                <Grid item xs={12} container justifyContent="flex-end">
                    <ButtonCore
                        disabled={buttonLoading}
                        variant="contained"
                        style={{
                            borderRadius: 8,
                            background: '#BA5A6B',
                            color: '#FFFFFF',
                            marginLeft: '8px',
                        }}
                        onClick={handleSubmit(onSubmit)}
                    >
                        {buttonLoading ? (
                            <CircularProgress color="inherit" size={25} />
                        ) : (
                            'Submit'
                        )}
                    </ButtonCore>
                </Grid>
            </Grid>
        </Grid>
    ) : (
        <>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh', // Adjust this as needed
                }}
            >
                <CircularProgress />
            </div>
        </>
    )
}

export default BlogModule

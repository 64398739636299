import React from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import HelpCenter from '../pages/HelpCanter/HelpCenter'
import Home from '../pages/Home/Home'
import ManageMemberships from '../pages/ManageMemberships/ManageMemberships'
import ManageUsers from '../pages/ManageUsers/ManageUsers'
import NotificationSettings from '../pages/Notifications/NotificationSettings'
import PromoCode from '../pages/PromoCode'
import ReportManagement from '../pages/ReportManagement/ReportManagement'
import Settings from '../pages/Settings/Settings'
import UserInformation from '../pages/UserInformation/UserInformation'
import UserRequest from '../pages/UserRequest/UserRequest'
import MatchMakeMeUsers from '../pages/MatchMakeMeUsers/MatchMakeMeUsers'
import MatchMakeMeInfo from '../pages/MatchMakeMeUsers/userAllMatchingInfo/MatchMakeMeUsersAllInfo'
// import { useParams } from "react-router-dom"
//!monil extra add
import MUser from '../pages/ManageUsers/UserAdvanceSearch'
import QuizModule from '../pages/QuizModule/QuizModule'
import HubList from '../pages/HubModule/HubList/index'
import HubAddOrEdit from '../pages/HubModule/HubModule'
import UserRecommendedList from '../pages/UserRecommendedList/index'
const Router = () => {
    const { path } = useRouteMatch()
    // const {id} = useParams()

    let routes = [
        {
            path: '',
            component: Home,
        },
        {
            path: 'settings',
            component: Settings,
        },

        {
            path: 'user-information/:id',
            component: UserInformation,
        },
        {
            path: 'report-management',
            component: ReportManagement,
        },
        {
            path: 'user-request',
            component: UserRequest,
        },
        {
            path: 'user-management',
            component: ManageUsers,
        },
        {
            path: 'user-connect-list/:id',
            component: MatchMakeMeInfo,
        },
        {
            path: 'faqs',
            component: HelpCenter,
        },

        {
            path: 'notification',
            component: NotificationSettings,
        },
        {
            path: 'membership',
            component: ManageMemberships,
        },
        {
            path: 'promocode',
            component: PromoCode,
        },
        {
            path: 'muser',
            component: MUser,
        },
        {
            path: 'quiz-module',
            component: QuizModule,
        },
        {
            path: 'hub',
            component: HubList,
        },
        {
            path: 'hub/:id',
            component: HubAddOrEdit,
        },
        { path: 'recommend-list/:id', component: UserRecommendedList },
    ]

    return (
        <>
            {routes.map((item, i) => {
                return (
                    <Route exact key={i} path={`${path}${item.path}`}>
                        <item.component />
                    </Route>
                )
            })}
        </>
    )
}

export default Router

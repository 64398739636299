import { set_message } from '../redux/ActionCreators'
import Store from '../redux/Store'
import { api_instance, api_instance_modal } from './Base_Api'

export const get_users_request = async (
    type,
    page,
    sort,
    date_order,
    pendingType
    // isSortingApplied
) => {
    const { search } = Store.getState().GeneralReducer
    // if (type === 'rejected' && isSortingApplied === false) {
    //     console.log('date_order', date_order, type, isSortingApplied)
    //     date_order = -1
    // }
    try {
        const { data } = await api_instance.get('/admin/users/request/list', {
            params: {
                type: type,
                search: search,
                page: page,
                limit: 10,
                sort: sort,
                order: date_order,
                pendingType: pendingType,
            },
        })

        return data
    } catch (error) {}
}

export const get_user_by_id = async (id) => {
    try {
        if (id === 'new') {
            return {}
        }
        const { data } = await api_instance.get(`/admin/users/user/${id}`)
        return data
    } catch (e) {
        console.log(e.message)
    }
}

export const get_all_dropdowns = async (id) => {
    try {
        const { data } = await api_instance.get(`/admin/users/getdropdown`)
        return data?.data?.dropDown
    } catch (e) {
        console.log(e.message)
    }
}
export const add_to_waiting_list = async (ids) => {
    try {
        const { data } = await api_instance.put(
            '/admin/users/request/waiting',
            ids
        )
        return data
    } catch (error) {}
}
export const remove_from_waiting_list = async (ids) => {
    try {
        console.log('api ids:', ids)
        const { data } = await api_instance.delete(
            '/admin/users/request/waiting',
            {
                data: {
                    ids,
                },
            }
        )
        return data
    } catch (error) {}
}
export const accept_requests = async (ids) => {
    try {
        const { data } = await api_instance.put(
            '/admin/users/request/accept',
            ids
        )
        return data
    } catch (error) {}
}
export const accept_request_notification = async (ids) => {
    try {
        const { data } = await api_instance_modal.put(
            '/admin/users/request/accept',
            ids
        )
        return data
    } catch (error) {}
}
export const reject_requests = async (ids) => {
    try {
        const { data } = await api_instance_modal.put(
            '/admin/users/request/reject',
            ids
        )
        Store.dispatch(
            set_message({
                message: data.data.message,
                color: 'success',
                mode: true,
                top: true,
            })
        )
        return data
    } catch (error) {}
}

export const get_users_count = async () => {
    try {
        const { data } = await api_instance_modal.get(
            '/admin/users/request/count'
        )
        return data
    } catch (error) {}
}

export const get_match_make_me_questionnair = async (id) => {
    try {
        let urlStr =
            id !== 'new'
                ? `/admin/users/match-make-me-questions?id=${id}`
                : `/admin/users/match-make-me-questions`
        const { data } = await api_instance.get(urlStr)
        return data
    } catch (e) {
        console.log(e.message)
    }
}

export const get_hubs = async (page, searchText, status) => {
    try {
        const { data } = await api_instance.post(`/admin/hub/list`, {
            page,
            search: searchText,
            status: status,
        })
        return data
    } catch (e) {
        console.log(e.message)
    }
}
export const get_hub_details_by_id = async (id) => {
    try {
        const { data } = await api_instance.get(`/admin/hub/${id}`)
        return data
    } catch (e) {
        console.log(e.message)
    }
}

export const add_hub = async (formData) => {
    try {
        console.log('formData', formData)
        const { data } = await api_instance.post(`/admin/hub/add`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        if (data?.data?.success) {
            Store.dispatch(
                set_message({
                    message: data?.data?.success,
                    color: 'success',
                    mode: true,
                })
            )
        }

        return data
    } catch (e) {
        console.log(e.message)
        Store.dispatch(
            set_message({
                message: e?.response?.data?.message,
                color: 'error',
                mode: true,
            })
        )
    }
}

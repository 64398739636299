import {
    Box,
    FormLabel,
    Grid,
    Typography,
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from '@material-ui/core'
import {
    TextField,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Stack,
} from '@mui/material'
import { GridExpandMoreIcon } from '@mui/x-data-grid'
import { styles } from '../../../styles/styles'

const LocationInformation = ({ register, accordionObj, setAccordionObj }) => {
    // console.log('checking_loc_error', errors?.address?.location)
    const style = styles()
    return (
        <Grid spacing={2} className={style.box}>
            <Accordion
                style={{ boxShadow: 'none' }}
                expanded={accordionObj?.isLocationInfo}
                // defaultExpanded={errors?.address ? true : false}
            >
                <AccordionSummary
                    onClick={() => {
                        setAccordionObj((prev) => ({
                            ...prev,
                            isLocationInfo: !prev.isLocationInfo,
                        }))
                    }}
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header-location-info-questionnair"
                >
                    <Typography
                        style={{
                            fontSize: '1.2rem',
                            textDecoration: 'underline',
                        }}
                    >
                        Location Information
                    </Typography>
                </AccordionSummary>

                <AccordionDetails>
                    <Grid container spacing={2}>
                        <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel htmlFor="country" sx={{ pt: 4 }}>
                                        Country:
                                    </FormLabel>
                                    <TextField
                                        {...register('address.country')}
                                        placeholder="Country"
                                        id="country"
                                        // value={""}
                                        // onChange={() => {}}
                                        fullWidth
                                        margin="normal"
                                        size="small"
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel htmlFor="state" sx={{ pt: 4 }}>
                                        State:
                                    </FormLabel>
                                    <TextField
                                        {...register('address.state')}
                                        placeholder="State"
                                        id="state"
                                        // value={""}
                                        fullWidth
                                        margin="normal"
                                        size="small"
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel htmlFor="city" sx={{ pt: 4 }}>
                                        City:
                                    </FormLabel>
                                    <TextField
                                        {...register('address.city')}
                                        placeholder="City"
                                        id="city"
                                        // value={""}
                                        // onChange={() => {}}
                                        fullWidth
                                        margin="normal"
                                        size="small"
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel htmlFor="living" sx={{ pt: 4 }}>
                                        Living:
                                    </FormLabel>
                                    <TextField
                                        {...register('living')}
                                        placeholder="Living"
                                        id="living"
                                        // value={""}
                                        // onChange={() => {}}
                                        fullWidth
                                        margin="normal"
                                        size="small"
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <FormLabel htmlFor="Longitude">
                                    Location (Longitude)
                                    <span
                                        style={{
                                            color: '#dc3545',
                                        }}
                                    >
                                        *
                                    </span>
                                </FormLabel>

                                <TextField
                                    {...register('address.location[0]')}
                                    placeholder="Longitude"
                                    id="longitude"
                                    // value={""}
                                    // onChange={() => {}}
                                    fullWidth
                                    margin="normal"
                                    size="small"
                                />
                                {/* {errors?.address &&
                                    errors.address.location[0] && (
                                        <span style={{ color: '#dc3545' }}>
                                            {errors.address.location[0].message}
                                        </span>
                                    )} */}
                            </Grid>
                        </Grid>
                        <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <FormLabel htmlFor="Latitude">
                                    Location (Latitude)
                                    <span
                                        style={{
                                            color: '#dc3545',
                                        }}
                                    >
                                        *
                                    </span>
                                </FormLabel>

                                <TextField
                                    {...register('address.location[1]')}
                                    placeholder="Latitude"
                                    id="latitude"
                                    // value={""}
                                    // onChange={() => {}}
                                    fullWidth
                                    margin="normal"
                                    size="small"
                                />
                                {/* {errors?.address &&
                                    errors?.address?.location[1] && (
                                        <span style={{ color: '#dc3545' }}>
                                            {
                                                errors?.address?.location[1]
                                                    .message
                                            }
                                        </span>
                                    )} */}
                            </Grid>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
    )
}

export default LocationInformation

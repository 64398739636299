import { useState, useEffect } from 'react'
import {
    Grid,
    Typography,
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from '@material-ui/core'
import {
    TextField,
    InputLabel,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Stack,
} from '@mui/material'
import { styles } from '../../../styles/styles'
import { GridExpandMoreIcon } from '@mui/x-data-grid'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { Controller } from 'react-hook-form'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
const SubscriptionAndActivity = ({
    register,
    form,
    setValue,
    expirePackage,
    lastActiveAt,
    subscriptionDropdownOptions,
    goldenAt,
    deletedAt,
    deActiveAt,
    accordionObj,
    setAccordionObj,
}) => {
    const style = styles()
    const { control } = form
    const [expirePackageDate, setExpirePackageDate] = useState(null)
    const [lastActivedDate, setLastActiveDate] = useState(null)
    const [goldenDate, setGoldenDate] = useState(null)
    const [deletedDate, setDeletedDate] = useState(null)
    const [deactivatedDate, setDeactivatedDate] = useState(null)

    console.log('--------', deActiveAt, deletedAt, goldenAt)
    useEffect(() => {
        register('expirePackage')
        register('lastActiveAt')
        register('goldenAt')
        register('deletedAt')
        register('deActiveAt')

        if (expirePackage) {
            setExpirePackageDate(new Date(expirePackage))
        }
        if (lastActiveAt) {
            setLastActiveDate(new Date(lastActiveAt))
        }
        if (goldenAt) {
            setGoldenDate(new Date(goldenAt))
        }
        if (deletedAt) {
            setDeletedDate(new Date(deletedAt))
        }
        if (deActiveAt) {
            setDeactivatedDate(new Date(deActiveAt))
        }
    }, [
        register,
        expirePackage,
        lastActiveAt,
        goldenAt,
        deletedAt,
        deActiveAt,
        setExpirePackageDate,
        setLastActiveDate,
        setGoldenDate,
        setDeletedDate,
        setDeactivatedDate,
    ])

    return (
        <Grid spacing={2} className={style.box}>
            <Accordion
                style={{ boxShadow: 'none' }}
                expanded={accordionObj?.isSubsAndActivity}
            >
                <AccordionSummary
                    onClick={() => {
                        setAccordionObj((prev) => ({
                            ...prev,
                            isSubsAndActivity: !prev.isSubsAndActivity,
                        }))
                    }}
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-subscription-and-activity-info--questionnair"
                >
                    <Typography
                        style={{
                            fontSize: '1.2rem',
                            textDecoration: 'underline',
                        }}
                    >
                        Subscription & Activity
                    </Typography>
                </AccordionSummary>

                {/* <Grid item container xs={4}>
                <Grid item xs={12}>
                    <Stack direction="column">
                        <InputLabel htmlFor="subscription">
                            Subscription:
                        </InputLabel>

                        <TextField
                            {...register('subscription')}
                            placeholder="Subscription"
                            id="subscription"
                            fullWidth
                            margin="normal"
                            size="small"
                        />
                    </Stack>
                </Grid>
            </Grid> */}
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <InputLabel htmlFor="subscription">
                                        Subscription
                                        <span
                                            style={{
                                                color: '#dc3545',
                                            }}
                                        >
                                            *
                                        </span>
                                    </InputLabel>
                                    <FormControlLabel
                                        style={{
                                            paddingTop: '16px',
                                            paddingLeft: '12px',
                                        }}
                                        control={
                                            <Controller
                                                {...register('subscription')}
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        labelId="subscription"
                                                        id="demo-simple-select"
                                                        fullWidth
                                                        margin="normal"
                                                        size="small"
                                                        // {...field}
                                                        // defaultValue={'0'}
                                                        value={
                                                            field.value
                                                                ? field.value
                                                                : ''
                                                        }
                                                        onChange={(event) =>
                                                            field.onChange(
                                                                event.target
                                                                    .value
                                                            )
                                                        }
                                                    >
                                                        <MenuItem value={'0'}>
                                                            Select Subscription
                                                        </MenuItem>
                                                        {subscriptionDropdownOptions?.map(
                                                            (option) => {
                                                                return (
                                                                    <MenuItem
                                                                        value={
                                                                            option.name
                                                                        }
                                                                    >
                                                                        {
                                                                            option.name
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            }
                                                        )}
                                                    </Select>
                                                )}
                                            />
                                        }
                                    />
                                </Stack>
                            </Grid>
                        </Grid>

                        <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <InputLabel htmlFor="expirePackage">
                                        Expire Package
                                        <span
                                            style={{
                                                color: '#dc3545',
                                            }}
                                        >
                                            *
                                        </span>
                                    </InputLabel>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                    >
                                        <DatePicker
                                            slotProps={{
                                                textField: {
                                                    fullWidth: true,
                                                    size: 'small',
                                                },
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} />
                                            )}
                                            value={expirePackageDate}
                                            onChange={(newValue) => {
                                                setExpirePackageDate(newValue)
                                                setValue(
                                                    'expirePackage',
                                                    newValue,
                                                    {
                                                        shouldValidate: true,
                                                        shouldDirty: true,
                                                    }
                                                )
                                            }}
                                            sx={{ pt: 2 }}
                                        />
                                    </LocalizationProvider>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <InputLabel htmlFor="lastActiveAt">
                                        Last Active At:
                                    </InputLabel>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                    >
                                        <DatePicker
                                            slotProps={{
                                                textField: {
                                                    fullWidth: true,
                                                    size: 'small',
                                                },
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} />
                                            )}
                                            value={lastActivedDate}
                                            onChange={(newValue) => {
                                                setLastActiveDate(newValue)
                                                setValue(
                                                    'lastActiveAt',
                                                    newValue,
                                                    {
                                                        shouldValidate: true,
                                                        shouldDirty: true,
                                                    }
                                                )
                                            }}
                                            sx={{ pt: 2 }}
                                        />
                                    </LocalizationProvider>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <InputLabel htmlFor="deActiveAt">
                                        DeActive At:
                                    </InputLabel>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                    >
                                        <DatePicker
                                            slotProps={{
                                                textField: {
                                                    fullWidth: true,
                                                    size: 'small',
                                                },
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} />
                                            )}
                                            value={deactivatedDate}
                                            onChange={(newValue) => {
                                                setDeactivatedDate(newValue)
                                                setValue(
                                                    'deActiveAt',
                                                    newValue,
                                                    {
                                                        shouldValidate: true,
                                                        shouldDirty: true,
                                                    }
                                                )
                                            }}
                                            sx={{ pt: 2 }}
                                        />
                                    </LocalizationProvider>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <InputLabel htmlFor="deletedAt">
                                        Deleted At:
                                    </InputLabel>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                    >
                                        <DatePicker
                                            slotProps={{
                                                textField: {
                                                    fullWidth: true,
                                                    size: 'small',
                                                },
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} />
                                            )}
                                            value={deletedDate}
                                            onChange={(newValue) => {
                                                setDeletedDate(newValue)
                                                setValue(
                                                    'deletedAt',
                                                    newValue,
                                                    {
                                                        shouldValidate: true,
                                                        shouldDirty: true,
                                                    }
                                                )
                                            }}
                                            sx={{ pt: 2 }}
                                        />
                                    </LocalizationProvider>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <InputLabel htmlFor="goldenAt">
                                        Golden At:
                                    </InputLabel>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                    >
                                        <DatePicker
                                            slotProps={{
                                                textField: {
                                                    fullWidth: true,
                                                    size: 'small',
                                                },
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} />
                                            )}
                                            value={goldenDate}
                                            onChange={(newValue) => {
                                                setGoldenDate(newValue)
                                                setValue('goldenAt', newValue, {
                                                    shouldValidate: true,
                                                    shouldDirty: true,
                                                })
                                            }}
                                            sx={{ pt: 2 }}
                                        />
                                    </LocalizationProvider>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
    )
}

export default SubscriptionAndActivity

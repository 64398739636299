import { useState, useEffect } from 'react'
import {
    FormLabel,
    Grid,
    Typography,
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from '@material-ui/core'
import {
    TextField,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Stack,
} from '@mui/material'
import { styles } from '../../../styles/styles'
import { Controller } from 'react-hook-form'
import { GridExpandMoreIcon } from '@mui/x-data-grid'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

const OtherInformation = ({
    register,
    control,
    timedBlock,
    setValue,
    lookingForDropdownOptions,
    statusDropdownOptions,
    accordionObj,
    setAccordionObj,
}) => {
    const style = styles()

    const [timedBlockDate, setTimedBlockDate] = useState(null)

    useEffect(() => {
        register('timedBlock')
        if (timedBlock) {
            setTimedBlockDate(new Date(timedBlock))
        }
    }, [register, setTimedBlockDate, timedBlock])

    return (
        <Grid spacing={2} className={style.box}>
            <Accordion
                style={{ boxShadow: 'none' }}
                expanded={accordionObj?.isOtherInfo}
            >
                <AccordionSummary
                    onClick={() => {
                        setAccordionObj((prev) => ({
                            ...prev,
                            isOtherInfo: !prev.isOtherInfo,
                        }))
                    }}
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-other-info--questionnair"
                >
                    <Typography
                        style={{
                            fontSize: '1.2rem',
                            textDecoration: 'underline',
                        }}
                    >
                        Other Information
                    </Typography>
                </AccordionSummary>

                <AccordionDetails>
                    <Grid container spacing={2}>
                        <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel
                                        htmlFor="whyToJoin"
                                        sx={{ pt: 4 }}
                                    >
                                        Why To Join:
                                    </FormLabel>
                                    <TextField
                                        {...register('whyToJoin')}
                                        placeholder="Why To Join"
                                        id="whyToJoin"
                                        multiline
                                        rows={3}
                                        fullWidth
                                        margin="normal"
                                        size="small"
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel
                                        htmlFor="dateingApproach"
                                        sx={{ pt: 4 }}
                                    >
                                        Dating Approach:
                                    </FormLabel>
                                    <TextField
                                        {...register('dateingApproach')}
                                        placeholder="Dating Approach"
                                        id="dateingApproach"
                                        multiline
                                        rows={3}
                                        fullWidth
                                        margin="normal"
                                        size="small"
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel
                                        htmlFor="spendOnFreeTime"
                                        sx={{ pt: 4 }}
                                    >
                                        Spend On Free Time:
                                    </FormLabel>
                                    <TextField
                                        {...register('spendOnFreeTime')}
                                        placeholder="Spend On Free Time"
                                        id="spendOnFreeTime"
                                        multiline
                                        rows={3}
                                        fullWidth
                                        margin="normal"
                                        size="small"
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel
                                        htmlFor="lookingFor"
                                        sx={{ pt: 4 }}
                                    >
                                        Looking For:
                                    </FormLabel>
                                    <FormControlLabel
                                        style={{
                                            paddingTop: '16px',
                                            paddingLeft: '12px',
                                        }}
                                        control={
                                            <Controller
                                                {...register('lookingFor')}
                                                control={control}
                                                render={({ field }) => (
                                                    <>
                                                        <div>
                                                            {console.log(
                                                                'aaaaaaaaaaa....',
                                                                field.value
                                                            )}
                                                        </div>
                                                        <Select
                                                            labelId="lookingFor"
                                                            id="demo-simple-select"
                                                            fullWidth
                                                            margin="normal"
                                                            size="small"
                                                            // {...field}
                                                            value={
                                                                field.value
                                                                    ? field.value
                                                                    : []
                                                            }
                                                            // defaultValue={['0']}
                                                            // multiple={true}
                                                            onChange={(event) =>
                                                                field.onChange(
                                                                    event.target
                                                                        .value
                                                                )
                                                            }
                                                        >
                                                            <MenuItem
                                                                value={'0'}
                                                            >
                                                                Select Looking
                                                                For
                                                            </MenuItem>
                                                            {lookingForDropdownOptions?.map(
                                                                (option) => {
                                                                    return (
                                                                        <MenuItem
                                                                            value={
                                                                                option.name
                                                                            }
                                                                        >
                                                                            {
                                                                                option.name
                                                                            }
                                                                        </MenuItem>
                                                                    )
                                                                }
                                                            )}
                                                        </Select>
                                                    </>
                                                )}
                                            />
                                        }
                                    />
                                </Stack>
                            </Grid>
                        </Grid>

                        <Grid container item xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel htmlFor="status" sx={{ pt: 4 }}>
                                        Status
                                        <span
                                            style={{
                                                color: '#dc3545',
                                            }}
                                        >
                                            *
                                        </span>
                                    </FormLabel>
                                    <FormControlLabel
                                        style={{
                                            paddingTop: '16px',
                                            paddingLeft: '12px',
                                        }}
                                        control={
                                            <Controller
                                                {...register('status')}
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        labelId="status"
                                                        id="demo-simple-select"
                                                        fullWidth
                                                        margin="normal"
                                                        size="small"
                                                        value={
                                                            field.value
                                                                ? field.value
                                                                : ''
                                                        }
                                                        onChange={(event) =>
                                                            field.onChange(
                                                                event.target
                                                                    .value
                                                            )
                                                        }
                                                        // multiple={true}
                                                    >
                                                        <MenuItem value={'0'}>
                                                            Select Status
                                                        </MenuItem>
                                                        {statusDropdownOptions?.map(
                                                            (option) => {
                                                                return (
                                                                    <MenuItem
                                                                        value={
                                                                            option.name
                                                                        }
                                                                    >
                                                                        {
                                                                            option.name
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            }
                                                        )}
                                                    </Select>
                                                )}
                                            />
                                        }
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid container item xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel htmlFor="step" sx={{ pt: 4 }}>
                                        Step:
                                    </FormLabel>
                                    <TextField
                                        {...register('step')}
                                        placeholder="Step"
                                        id="step"
                                        // value={""}
                                        // onChange={() => {}}
                                        fullWidth
                                        margin="normal"
                                        size="small"
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid container item xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel
                                        htmlFor="invitationCode"
                                        sx={{ pt: 4 }}
                                    >
                                        Invitation Code:
                                    </FormLabel>
                                    <TextField
                                        {...register('invitationCode')}
                                        placeholder="Invitation Code"
                                        id="invitationCode"
                                        // value={""}
                                        // onChange={() => {}}
                                        fullWidth
                                        margin="normal"
                                        size="small"
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid container item xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel
                                        htmlFor="blockReason"
                                        sx={{ pt: 4 }}
                                    >
                                        Block Reason:
                                    </FormLabel>
                                    <TextField
                                        {...register('blockReason')}
                                        id="blockReason"
                                        placeholder="Block Reason"
                                        // value={""}
                                        // onChange={() => {}}
                                        fullWidth
                                        margin="normal"
                                        size="small"
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel
                                        htmlFor="timedBlock"
                                        sx={{ pt: 4 }}
                                    >
                                        Timed Block:
                                    </FormLabel>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                    >
                                        <DatePicker
                                            slotProps={{
                                                textField: {
                                                    fullWidth: true,
                                                    size: 'small',
                                                },
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} />
                                            )}
                                            value={timedBlockDate}
                                            onChange={(newValue) => {
                                                setTimedBlockDate(newValue)
                                                setValue(
                                                    'timedBlock',
                                                    newValue,
                                                    {
                                                        shouldValidate: true,
                                                        shouldDirty: true,
                                                    }
                                                )
                                            }}
                                            sx={{ pt: 2 }}
                                        />
                                    </LocalizationProvider>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
    )
}

export default OtherInformation

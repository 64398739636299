import React, { useState } from 'react'
import imageCompression from 'browser-image-compression'
import { set_message } from '../../redux/ActionCreators'
import Store from '../../redux/Store'

import { Add } from '@material-ui/icons'

const UploadUserImage = ({ setUserImages, setBlobImageLocalURL }) => {
    const handleOnChange = async (event) => {
        const imageFile = event?.target?.files[0]
        console.log('imageFile', imageFile?.type)
        setBlobImageLocalURL(URL.createObjectURL(imageFile))
        setUserImages(imageFile)
        if (!imageFile?.type || !imageFile?.type?.startsWith('image')) {
            Store.dispatch(
                set_message({
                    mode: true,
                    message: 'Only image type file is allowed.',
                    color: 'error',
                })
            )
            return
        }
    }
    return (
        <div style={{ position: 'relative' }}>
            <input
                style={{
                    position: 'absolute',
                    top: '-10%',
                    left: '-10%',
                    lineHeight: '15rem',
                    opacity: 0,
                }}
                onChange={handleOnChange}
                accept="image/*"
                maxlength="5728640"
                id="image_upload"
                type="file"
            />
            <Add
                style={{
                    width: '200px',
                    height: '200px',
                }}
            ></Add>
        </div>
    )
}

export default UploadUserImage
